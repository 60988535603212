
.button-right {
  position: absolute;
  height: 100%;
  right: 42vw; 
  cursor: pointer;
}

.button-left {
  position: absolute;
  left: 43.2vw;
  height: 3.76vh;
  cursor: pointer;
  
}
