.guestbook-contents{
  width: 60%;
  position: absolute;
  font-weight: bold;
  top: 10vh;

}

.button-container{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.guestbook-add-button{
  background-image: url('../../assets/guestbook/guestbook_add_button.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 170px;
  height: 50px;
  border: 0;
  margin: 20px;
  cursor: pointer;
}

.guestbook-add-button:hover{
  background-image: url('../../assets/guestbook/guestbook_add_button2.png');
}

.guestbook-title{
  font-size: 32px;
  font-weight: bold;
}

.guestbook-subtitle{
  font-size: 16px;
  font-weight: 200;
}