.container {
  width: 60vw;
  height: auto;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  white-space: pre-line;
}

.container_empty {
  width: 60vw;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.no-text {
  color: #b5b5b5;
}
.footer {
  width: 100%;
  height: 1rem;
  background-color: blue;
}
