@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

.description {
  width: 60%;
  display: flex;
  flex-direction: row;
  padding: 10% 20%;
  gap: 5%;
}

.image-container {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.description-img {
  width: 100%;
  height: 100%;
}

.description-heading {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.subtitle {
  color: var(--gray-3, #93979f);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.title {
  color: var(--black, #00031c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.summary {
  color: var(--black, #00031c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
  margin: 5% 0;
}

.description-content p {
  color: var(--black, #00031c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Pretendard;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem;
  margin: 0 0 5% 0;
}
