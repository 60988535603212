.body {
  overflow: auto;
}

.main-sector {
  display: flex;
  border-top: 3vh;
  overflow: hidden;
}

.button-sector {
  position: absolute;
  top: 93.54vh;
  width: 100vw;
  height: 3.76vh;
  display: flex;
  position: fixed;
}
