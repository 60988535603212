.comment-card {
  background-image: url("../../assets/guestbook/guestbook_commentCard.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 15vw;
  height: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5vw;
  margin: 1vw;}

.comment-text {
  width: inherit;
  height: inherit;
  word-wrap: break-word; /* This property ensures the text wraps to the next line if it overflows the container's width */
  max-width: 100%; /* Prevent the text from extending beyond the container's width */
  max-height: 100%;
  font-size: 16px;
}

.delete-button {
  background-image: url("../../assets/guestbook/guestbook_delete_button.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: none;
  width: 1.2vw;
  height: 1.2vw;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 8%;
  position: fixed;
}

.modal-content p{
  display: inline-block;
  margin-right: 1rem;
}

.button-container{
  display: flex;
  justify-content: right;
}

@media (min-width: 2200px) {
  .comment-text {
    font-size: 24px;
  }
}