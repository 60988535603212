@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

.header {
  font-family: 'Pretendard';
  position: fixed;
  width: 100vw;
  height: 5vh;
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 5px;
  z-index: 2;
  position: relative;
  position: fixed;
}

.logo-container {
  position: absolute;
  left: 48.93%;
  cursor: pointer;
  width: 2.07%;
  top: 26.25%;
}

.header-container {
  font-style: normal;
  font-weight: 500;
  font-size: 0.625vw;
  line-height: 1.11vh;
  text-decoration: none;
  color: #00031c;
  cursor: pointer;
   
}

.about {
  position: absolute;
  left: 0.93%;
  top: 39%;
}

.guest {
  position: absolute;
  right: 0.93%;
  top: 39%;

}

.link-decoration {
  font-style: normal;
  font-weight: 500;
  font-size: 1.04vw;
  line-height: 2.22vh;
  text-decoration: none;
  color: #00031c;
}

.link-decoration:hover {
  text-decoration: underline;
}

.active {
  text-decoration: underline;
}
