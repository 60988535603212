.film-container {
  margin-right: 0.1%;
  /* width: 14.2%; */
  height: 100%;
  position: relative;
}

.film-img {
  height: 100%;
}
.film-img_hover {
  position: absolute;
  width: 100%;
  height: 12.08%;
  left: 0;
  bottom: 0;
  visibility: hidden;
}
.film-img_hover.hovered {
  visibility: visible;
}
.film_description {
  width: 100%;
  height: 100%;
  border-top: 3px solid black;
  background-color: white;
  font-family: 'Pretendard-Regular', sans-serif;
  color: black;
}
._title {
  font-size: 36px;
  font-weight: 700;
  font-family: 'Pretendard', sans-serif;
  margin: 1.2rem;
  margin-left: 1.7rem;
}
.credit {
  display: flex;
  margin: -0.3rem;
  margin-left: 1.7rem;
}
.credit strong {
  font-weight: bold;
}

._developer {
  margin-left: 1rem;
}
