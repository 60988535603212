.introduction {
  position: absolute;
  top: 8.819vh;
  left: 3.08vw;
  width: 70.97vw;
  height: 64.65vh;;
  justify-content: left;
}

.logo {
  height: 100%;
}

.sub {
  position: absolute;
  top: 78.95vh;
  left: 3.98vw;
}

.arrow {
  position: absolute;
  top: 89.51vh;
  left: 3.98vw;
}