@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

* {
    margin: 0;
    padding: 0;
}

.members {
    display: grid;
    grid-template-columns: 62.18vw;
    width: 60%;
    padding: 10% 20%;
}

.members-name {
    color: var(--black, #00031C);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Pretendard;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.members-description{
    color: var(--black, #00031C);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Pretendard;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
    margin-top: 1.5rem;
    margin-bottom: 8rem;
}

.Members_members {
    display: grid;
    grid-template-columns: repeat(4,16vw);
    grid-template-rows: repeat(2, 34vh);
    row-gap: 80px;
    column-gap: 50px;
}