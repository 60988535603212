@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

.Member_item {
  width: 14.37vw;
  height: 26.57vh;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
}

.snowman {
  width: 12vw;
  height: 14vw;
  flex-shrink: 0;
  margin: 0;
  overflow: hidden; /* Clip the image if it exceeds the container */
}

.snowman img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio */
}

.nameplus {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 38px 0 20px 0;
  font-weight: bold;
  font-size: 2rem;
}

.job {
  color: grey;
  font-weight: bold;
  font-size: 1.6rem;
}
