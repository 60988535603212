.iframe-container {
  position: fixed;
  top: 5vh;
  width: 100vw;
  height: 95vh;
  border: none;
}

.string-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border:none;
  
}
