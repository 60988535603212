/* @font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 900;
  font-style: normal;
} */
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

.films-container {
  position: absolute;
  height: 86.53vh;
  top: 5vh;
  left: 80.74vw;
  flex-shrink: 0;
  min-width: 100%;
  z-index: 0;
}

.films-content {
  display: flex;
  position: absolute;
  margin-left: 0.1%;
  top: 5.21%;
  left: 0;

  height: 89%;
}

.filmbody {
  position: absolute;
  top: 0;
  left: 0;
  height: 86.53vh;
}