.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(85, 85, 85, 0.6);
    z-index: 1;
  }

  .modal {
    width: 500px;
    height: 500px;
    margin-top: 10vh;
    border: none;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 0;
    overflow: hidden;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }