.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cancel-button {
  background-image: url("../../assets/guestbook/guestbook_cancel_button.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  width: 30px;
  height: 30px;
  margin: 10px;
  border: 0;
  cursor: pointer;
}

.comment-title{
  font-size: 24px;
  font-weight: bold;
  margin: 20px;
}

.textarea {
  margin: 20px;
  width: 455px;
  height: 300px;
  overflow: hidden;
  resize: none;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 30px;
}


.submit-button {
  background-image: url("../../assets/guestbook/guestbook_submit_button.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 60px;
  height: 40px;
  margin-right: 20px;
  border: 0;
  float: right;
  cursor: pointer;
}

.submit-button:hover{
  background-image: url("../../assets/guestbook/guestbook_submit_button2.png");
}